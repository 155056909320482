import { GET_LIST, GET_MANY } from 'react-admin';

const getSharedIpAddresses = ({ response, url, options, httpClient, params, apiUrl }) => ({
  ...response,
  json: response.json.map((item) => ({
    ...item,
    id: item.ipAddress,
  })),
});

export default {
  [GET_LIST]: getSharedIpAddresses,
  [GET_MANY]: getSharedIpAddresses,
};
