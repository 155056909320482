import { GET_LIST, GET_MANY } from 'react-admin';

const getFingerprintsSharedList = ({ response, url, options, httpClient, params, apiUrl }) => ({
  ...response,
  json: response.json.map((item) => ({
    ...item,
    id: item.fingerprint,
  })),
});

export default {
  [GET_LIST]: getFingerprintsSharedList,
  [GET_MANY]: getFingerprintsSharedList,
};
