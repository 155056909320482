import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useNotify, useRefresh, useDataProvider } from 'react-admin';

import { UserDetails } from '../types';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    color: theme.palette.secondary.contrastText,
  },
}));

interface Props {
  user: UserDetails;
}

const BlockShippingButton = ({ user }: Props) => {
  const { shippingBlocked, id: rolloId, cognitoUserId: cognitoId } = user;

  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = async () => {
    try {
      setLoading(true);

      await dataProvider.post('blockShipping', {
        data: {
          rolloId,
          cognitoId,
        },
        blocked: !shippingBlocked,
      });

      refresh();
    } catch (error: any) {
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button variant="outlined" onClick={handleClick} disabled={loading} className={classes.button}>
      {!shippingBlocked ? 'Disable shipping' : 'Enable shipping'}
    </Button>
  );
};

export default BlockShippingButton;
