import devices from './devices';
import debtors from './debtors';
import debtorsStatuses from './debtorsStatuses';
import blocklist from './blocklist';
import sharedWarehouses from './sharedWarehouses';
import sharedIpAddresses from './ipAddressesSharedList';
import sharedFingerprints from './fingerprintsSharedList';
import fraudTypes from './fraudTypes';
import userActivity from './userActivity';
import creditCodes from './creditCodes';
import claims from './claims';
import userActivityTypes from './userActivityTypes';

const customResponses = {
  devices,
  userDevices: devices,
  debtors,
  debtorsStatuses,
  blocklist,
  sharedWarehouses,
  sharedIpAddresses,
  sharedFingerprints,
  fraudTypes,
  userActivity,
  creditCodes,
  claims,
  userActivityTypes,
};

export default customResponses;
