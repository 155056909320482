import React, { useEffect, useState } from 'react';

import { TextField, useDataProvider, useVersion } from 'react-admin';

import { Datagrid } from 'components/list';
import { DateField, PriceField } from 'components/fields';
import { makeStyles } from '@material-ui/core';
import { keyBy } from 'lodash';
import Pagination from 'components/pagination/Pagination';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
  expandHeader: {
    width: 'auto',
  },
  expand: {
    backgroundColor: theme.palette.background.default,
  },
  expandCell: {
    padding: '16px 0',
  },
}));

const ExpandedArea = (props) => {
  const { record } = props;

  const data = keyBy(
    record.fingerprints.map((item) => ({ id: item })),
    (item) => item.id
  );

  const ids = Object.keys(data);

  return (
    <Datagrid data={data} ids={ids} resource="multipleDataUsers" currentSort={{ field: 'id' }}>
      <TextField source="id" label="Credit card fingerprint" sortable={false} />
    </Datagrid>
  );
};

const MultipleCardsList = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const version = useVersion();

  const [users, setUsers] = useState<any>([]);
  const [currentTotal, setCurrentTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const getUsersList = async () => {
      const { data, total } = await dataProvider.getList('multipleDataUsers', {
        pagination: { page, perPage },
        filter: {
          fingerprints: '3',
        },
      });

      setCurrentTotal(total);
      setUsers(data);
      setLoaded(true);
    };

    getUsersList();
  }, [dataProvider, page, perPage, version]);

  const updatePerPage = (newPerPage) => {
    if (page > Math.ceil(currentTotal / newPerPage)) {
      setPage(1);
    }
    setPerPage(newPerPage);
  };

  return (
    <Card>
      <Datagrid
        loaded={loaded}
        expand={<ExpandedArea />}
        isRowExpandable
        rowClick="expand"
        classes={{
          expandHeader: classes.expandHeader,
          expandRoot: classes.expand,
          expandCell: classes.expandCell,
        }}
        showExpandButton={false}
        data={keyBy(users, 'id')}
        ids={users.map(({ id }) => id)}
        resource="multipleDataUsers"
        currentSort={{ field: 'id', order: 'DESC' }}>
        <TextField source="userName" label="Name" />

        <TextField source="email" label="Email" />

        <TextField source="phoneNumber" label="Phone" />

        <DateField
          source="createdAt"
          label="Date"
          options={{
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }}
          sortable={false}
        />

        <TextField source="shipments" label="Shipments" />

        <PriceField source="totalSpentAmount" label="Spend" />
      </Datagrid>
      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={updatePerPage}
        total={currentTotal}
      />
    </Card>
  );
};

export default MultipleCardsList;
