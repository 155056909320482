import React, { useEffect, useState, useMemo } from 'react';
import { TextField, DateField, useRedirect, useDataProvider, useVersion } from 'react-admin';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core';
import { keyBy } from 'lodash';

import { Datagrid } from 'components/list';
import Pagination from 'components/pagination/Pagination';
import { ActionField, PriceField } from 'components/fields';

import EnableUserAction from './EnableUserAction';
import BypassFraudAction from './BypassFraudAction';
import TextFieldWithAction from './TextFieldWithAction';

const useStyles = makeStyles((theme) => ({
  expandHeader: {
    width: 'auto',
  },
  expand: {
    backgroundColor: theme.palette.background.default,
  },
  expandCell: {
    padding: '16px 0',
  },
}));

const ExpandedArea = (props) => {
  const { record } = props;

  const [currentTotal, setCurrentTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(5);

  const redirect = useRedirect();

  const data = useMemo(() => {
    const start = (page - 1) * perPage;
    const end = (page - 1) * perPage + perPage;

    const paginatedData = record.users.slice(start, end);
    return keyBy(paginatedData, ({ uniqueId }) => uniqueId);
  }, [page, perPage, record.users]);

  const ids = Object.entries(data).map(([id]) => id);

  useEffect(() => {
    setCurrentTotal(record.users.length);
  }, [record.users]);

  const updatePerPage = (newPerPage) => {
    if (page > Math.ceil(currentTotal / newPerPage)) {
      setPage(1);
    }
    setPerPage(newPerPage);
  };

  return (
    <>
      <Datagrid
        data={data}
        ids={ids}
        resource="sharedWarehouses"
        currentSort={{ field: 'id' }}
        rowClick={(_id, _basePath, record) => {
          redirect('show', '/users', record.id);
        }}>
        <TextField source="name" label="Name" />
        <TextField source="phoneNumber" label="Phone" />
        <TextField source="email" label="Email" />
        <DateField
          source="createdAt"
          label="Registered"
          options={{
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }}
        />
        <TextField source="shipments" label="Shipments" />
        <PriceField source="totalSpentAmount" label="Spend" />
        <ActionField>
          <EnableUserAction />
          <BypassFraudAction />
        </ActionField>
      </Datagrid>
      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={updatePerPage}
        total={currentTotal}
      />
    </>
  );
};

const SharedWarehousesList = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const version = useVersion();

  const [warehouses, setWarehouses] = useState<any>([]);
  const [currentTotal, setCurrentTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const getWarehouses = async () => {
      const { data, total } = await dataProvider.getList('sharedWarehouses', {
        pagination: { page, perPage },
      });

      setCurrentTotal(total);
      setWarehouses(data);
      setLoaded(true);
    };

    getWarehouses();
  }, [dataProvider, page, perPage, version]);

  const updatePerPage = (newPerPage) => {
    if (page > Math.ceil(currentTotal / newPerPage)) {
      setPage(1);
    }
    setPerPage(newPerPage);
  };

  return (
    <Card>
      <Datagrid
        loaded={loaded}
        expand={<ExpandedArea />}
        isRowExpandable
        rowClick="expand"
        classes={{
          expandHeader: classes.expandHeader,
          expandRoot: classes.expand,
          expandCell: classes.expandCell,
        }}
        showExpandButton={false}
        data={keyBy(warehouses, 'id')}
        ids={warehouses.map(({ id }) => id)}
        resource="sharedWarehouses"
        currentSort={{ field: 'id', order: 'DESC' }}>
        <TextField source="warehouseAddress.addressLine" label="From Address" />
        <TextFieldWithAction source="users.length" label="Users" textAlign="right" />
      </Datagrid>
      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={updatePerPage}
        total={currentTotal}
      />
    </Card>
  );
};

export default SharedWarehousesList;
